const calculate_principal = (P, r, n, t) => {
  return P * Math.pow((1 + r / n), (t * n));
}

const calculate_compound_per_time = (principal, monthlyContribution,interestRate, time) => {
  let P   = principal;              // principal or initial value
  let PMT = monthlyContribution;    // monthly contribution
  let r   = interestRate            // annual interest rate
  let n   = 12                      // number of times the interest is compounded per year #magic_number
  let t   = time                    // the number of years

  const principalCompounded            = calculate_principal(P, r, n, t);
  const monthlyContributionsCompounded = PMT * (((Math.pow((1+r/n), t*n)) - 1) / (r/n))

  return (principalCompounded + monthlyContributionsCompounded).toFixed(2)
}

export const yearly_compounds = (years, principal, monthlyContribution, annualInterestRate) => {
  let year;
  let yearCompounds = {}
  let annualRate = annualInterestRate/100.0;        // annual interest rate #magic_number

  for (year = 1; year <= years ; year ++) {
    yearCompounds[year] = calculate_compound_per_time(principal, monthlyContribution, annualRate, year )
  }

  return yearCompounds;
}

const isMultipleByInterval = (n, interval) => {
  return n % interval === 0;
}

export const intervaled_compounds = (yearlyCompounds, interval) => {

  const newIntervalCompounds = yearlyCompounds.reduce((reducedArray, value, index) => {
    if(isMultipleByInterval(index, interval)) {
      return [...reducedArray, value];
    }
    return reducedArray;
  }, [])

  return newIntervalCompounds;
}
