import React from 'react'

const SvgComponent = props => (
  <svg width={12} height={4} {...props}>
    <title>{'Minus'}</title>
    <path d="M0 .5h12v3H0z" fill="#838099" fillRule="evenodd" />
  </svg>
)

export default SvgComponent
