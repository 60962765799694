import React from "react";
import { isAbsolute } from "path";
// import classNames from "classnames";

export default class UspBadge extends React.Component {
  render() {
    let styles = {
      top: this.props.posTop + "px",
      right: this.props.posRight + "px",
      position: "absolute",
      backgroundImage: `url(${this.props.bgImage})`
    };
    let classes = "badge right " + this.props.extraClasses;
    return (
      <div className={classes} style={styles}>
        {this.props.children}
      </div>
    );
  }
}
