import React from "react";

export default class Features extends React.Component {
  render() {
    return (
      <section className="features">
        <a name="features" />
        <div className="container">
          <h2 className="section-title">{this.props.title}</h2>
          <div className="feature-list columns">{this.props.children}</div>
        </div>
      </section>
    );
  }
}
