import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export const YearsDropdown = ({handleAgeDropDownClick, handleAgeClick, selectedYears, years, isAgeDropdownActive}) => (
  <div onClick={handleAgeDropDownClick} className={`dropdown ${isAgeDropdownActive ? 'is-active': ''}`}>
    <div className="dropdown-trigger">
      <div className="dropdown-label" aria-haspopup="true" aria-controls="dropdown-menu2">
        <span>
          {selectedYears} jaar
        </span>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faCaretDown} size="lg" color="rgb(92, 110, 141)"/>
        </span>
      </div>
    </div>
    <div className="dropdown-menu" id="dropdown-menu2" role="menu">
      <div className="dropdown-content">
        {
          years.map((year) => {
            return (
              <div key={year} className="dropdown-item" onClick={() => handleAgeClick(year)}>
                {year}
              </div>
            )
          })
        }
      </div>
    </div>
  </div>
)
