import React from "react";
import TrackingButton from "../TrackingButton";

export default class Feature extends React.Component {
  render() {
    const styles = { backgroundImage: `url(${this.props.image})` };
    return (
      <div className="feature column has-text-centered">
        <div className="feature-wrapper">
          <div className="feature-image">
            {this.props.image ? (
              <img src={this.props.image} />
            ) : (
              <h2>{this.props.nr}</h2>
            )}
          </div>
          <p className="feature-title">{this.props.title}</p>
          <p className="feature-description">{this.props.text}</p>
          {this.props.buttonText && <div className="button-wrapper">
            <TrackingButton
              id={this.props.trackId}
              experiment={this.props.experiment}
              extraClasses="full-width is-large"
              href={this.props.href}>
              {this.props.buttonText}
            </TrackingButton>
          </div>}
        </div>
      </div>
    );
  }
}
