import React from 'react'
import Collapsible from '../components/Collapsible';

import FaqQuestions from '../static/faq-questions';

const FaqContainer = (props) => {
  return (
    <div className="container" style={{whiteSpace: 'pre-wrap'}}>
      <h2 className="title">Veelgestelde vragen?</h2>
      {FaqQuestions.questions.map((question, i) => (
        <div style={{marginBottom: '1rem'}}>
          <Collapsible {...question} key={i} id={i} />
        </div>
      ))}
    </div>
  )
}

export default FaqContainer
