import React from "react";
import FontAwesomeIcon from "react-fontawesome";

import { emitter } from "@marvelapp/react-ab-test";

import axios from "axios";
import { emailRegex } from "../regex";
import TrackingButton from "./TrackingButton";

export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      variant: this.props.variant,
      isEmailValid: true,
      isSignupCompleted: false,
      signUpErrorMessageEmail: null
    };
  }

  // change also resets the error message
  //
  handleChangeName = e => {
    this.setState({
      name: e.target.value,
      signUpErrorMessageName: "",
      isNameValid: true
    });
  };

  // change also resets the error message
  //
  handleChangeCasus = e => {
    this.setState({
      casus: e.target.value,
      signUpErrorMessageCasus: "",
      isCasusValid: true
    });
  };

  // change also resets the error message
  //
  handleChangeEmail = e => {
    this.setState({
      email: e.target.value,
      signUpErrorMessageEmail: "",
      isEmailValid: true
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    const { email } = this.state;

    // check validity
    const isEmailValid = emailRegex.test(email);

    var ok = true;

    if (!isEmailValid) {
      this.setState({
        isEmailValid: isEmailValid,
        signUpErrorMessageEmail: "Voer een geldig email adres in"
      });
      ok = false;
    }

    if (!ok) {
      this.setState({ submitting: false });
      return;
    }

    this.setState({ submitting: true });

    await this.sendSignup();

    // Send event to Segment
    //
    emitter.emitWin(this.props.experiment);

    // reset
    //
    this.setState({
      email: "",
      signUpErrorMessageEmail: ""
    });
  };

  sendSignup = () => {
    const { email, variant } = this.state;
    axios.get(`${this.props.endpoint}?email=${email}&variant=${variant}`);
    this.setState({
      isSignupCompleted: true,
      submitting: false
    });

    // make the message go away again after 3 sec
    //
    setTimeout(() => {
      this.setState({ isSignupCompleted: false });
    }, 3000);
  };

  render() {
    const {
      isSignupCompleted,
      submitting,
      signUpErrorMessageEmail,
      email,
      isEmailValid
    } = this.state;

    // isSignupCompleted = true;
    // submitting = true;
    // isEmailValid = false;
    // signUpErrorMessage = "Test Error!"

    let style = {};
    if (this.props.image) {
      style = {
        backgroundImage: `url(${this.props.image})`,
        backgroundSize: "cover"
      };
    }

    return (
      <section ref="signup" className="signup is-medium" style={style}>
        <a name="signup" />
        {!isSignupCompleted && (
          <div className="container">
            <h2 className="section-title">{this.props.cta}</h2>
            <p>{this.props.explain}</p>
            <div className="signup-form">
              <form
                id="signup__form"
                className="signup__form columns"
                onSubmit={this.onSubmit}>
                <div className="field signup-field-email column">
                  <div className="control">
                    <p className="input-label">Email*</p>
                    <input
                      className={`input ${!isEmailValid ? "is-danger" : ""}`}
                      type="text"
                      placeholder="Johndoe@email.com"
                      value={email}
                      onChange={this.handleChangeEmail}
                    />
                    <FontAwesomeIcon
                      className="fa email-icon"
                      name="envelope"
                    />
                  </div>
                  {signUpErrorMessageEmail && (
                    <span className="signup-error is-danger">
                      {signUpErrorMessageEmail}
                    </span>
                  )}
                </div>
                <div className="field signup-field-button column">
                  <div className="control">
                    <TrackingButton
                      id="signup_cta"
                      experiment={this.props.experiment}
                      variant={this.props.variant}
                      extraClasses={`is-large ${
                        submitting ? "is-loading" : ""
                      }`}>
                      Verzenden
                    </TrackingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {isSignupCompleted && (
          <div className="container">
            <h2 className="signup-success is-success">
              {this.props.successMsg}
            </h2>
          </div>
        )}
      </section>
    );
  }
}
