import React, { ComponentType } from "react";
import * as ls from "local-storage";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import "./App.scss";

import { zapierWebhook } from "./config";
import CookieConsent from "./components/CookieConsent";
import Feature from "./components/Feature/Feature";
import Features from "./components/Feature/Features";
import InDepth from "./components/InDepth/InDepth";
import InDepthItem from "./components/InDepth/InDepthItem";
import Signup from "./components/Signup";
import StaticHeader from "./components/Header/StaticHeader";
import TrackingButton from "./components/TrackingButton";
import UspBadge from "./components/UspBadge";

import {
  Experiment,
  Variant,
  emitter,
  experimentDebugger,
  segmentHelper
} from "@marvelapp/react-ab-test";

import { GraphContainer } from "./containers/GraphContainer/GraphContainer";
import FaqContainer from "./containers/FaqContainer";

segmentHelper.enable();
// experimentDebugger.enable();

const urlPropsQueryConfig = {
  v: { type: UrlQueryParamTypes.string }
};

// define variants and global _experiment name for buttons to use
// @TODO make this come from some kind of config
//
const _experiment = "Voor Strax";
emitter.defineVariants(
  _experiment,
  ["ratio", "dromen", "stabiel", "zwaar"],
  [25, 25, 25, 25]
);

// Called when the experiment is displayed to the user.
//
emitter.addPlayListener(function(expName, varName) {
  console.log(`Displaying experiment ${expName} variant ${varName}`);
  window.analytics.page(varName);
});

// Called when a 'win' is emitted, in this case by this.refs.experiment.win()
//
emitter.addWinListener(function(expName, varName) {
  console.log(`Variant ${varName} of experiment ${expName} was clicked`);
});

var userIdentifier;
const identifierString = "experiment_user";
const uuidv4 = require("uuid/v4");

function getUserIdentifier() {
  if (ls.get(identifierString)) {
    userIdentifier = ls.get(identifierString);
  } else {
    userIdentifier = uuidv4();
    ls.set(identifierString, userIdentifier);
  }
}

const variants = {
  a: "ratio",
  b: "dromen",
  c: "stabiel",
  d: "zwaar"
};

const { a, b, c, d } = { ...variants };

class App extends React.Component {
  static propTypes = {
    // URL props are automatically decoded and passed in based on the config
    v: PropTypes.string,

    // change handlers are automatically generated when given a config.
    // By default they update that single query parameter and maintain existing
    // values in the other parameters.
    onChangeV: PropTypes.func
  };

  static defaultProps = {
    v: "none"
  };

  componentWillMount() {
    if (this.props.v !== "none") {
      let deeplinkedVersion = this.props.v;
      switch (deeplinkedVersion) {
        case "ratio":
          emitter.setActiveVariant("Voor Strax", "ratio");
          return;
        case "dromen":
          emitter.setActiveVariant("Voor Strax", "dromen");
          return;
        case "stabiel":
          emitter.setActiveVariant("Voor Strax", "stabiel");
          return;
        case "zwaar":
          emitter.setActiveVariant("Voor Strax", "zwaar");
          return;
      }
    }
    getUserIdentifier();
    window.analytics.identify(userIdentifier);
  }

  render() {
    const { v, onChangeV } = this.props;

    return (
      <div className="App">
        <Experiment name={_experiment}>
          <Variant name={a}>
            <div className={`variant-${a}`}>
              <StaticHeader
                logo={`/assets/variant-${a}/logo.png`}
                title="Meebeleggen met professionals"
                tagline={[
                  "Gemiddeld ",
                  <em>7% rendement</em>,
                  " op jaarbasis over 20 jaar."
                ]}
                buttonText="Ik wil meer weten"
                trackId="header-ratio"
                href="#signup"
                showRating={false}
                align="left"
                extraClasses="personal">
                <UspBadge
                  bgImage={`/assets/variant-${a}/badge-background.png`}
                  posTop="220"
                  posRight="50">
                  <p>Beleg nu mee met</p>
                  <h1>7%</h1>
                  <p>rendement</p>
                  <p>op jaarbasis*</p>
                </UspBadge>
              </StaticHeader>
              <Features>
                <Feature
                  nr="1"
                  image={`/assets/variant-${a}/meebeleggen.png`}
                  title="Uniek hoog rendement"
                  text="Strax maakt het mogelijk mee te beleggen met pensioenfondsen en zo mee te liften op de kennis, ervaring en infrastructuur van topbeleggers."
                  buttonText="Vertel me meer"
                  href="#signup"
                  extraClasses="dromen"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="2"
                  image={`/assets/variant-${a}/hoog-rendement.png`}
                  title="Stabiel beleggen"
                  text="Over de afgelopen 10 jaar hebben de fondsen waarmee Strax samenwerkt een gemiddeld rendement behaald van 7% op jaarbasis."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="3"
                  image={`/assets/variant-${a}/altijd-bij-je-geld.png`}
                  title="Altijd direct opneembaar"
                  text="De beleggingsrekening van Strax werkt met een vaste inleg per maand, maar is altijd per direct opneembaar."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
              </Features>
              <section name="graph-container">
                <GraphContainer experiment={_experiment} variant={a} />
              </section>
              <InDepth extraClassNames="blue-gradient">
                <InDepthItem
                  title="Unieke beleggingen"
                  image="/assets/variant-ratio/erasmus.jpg"
                  direction="rtl">
                  <p>
                    Door onze grote schaalomvang maakt Strax unieke beleggingen
                    bereikbaar voor u. Strax belegt bijvoorbeeld in grote
                    infrastructuurprojecten in Nederland met hoge stabiele
                    rendementen. Stap nu in en profiteer ook van deze unieke
                    kans.
                  </p>
                  <TrackingButton
                    button_id="waarin-belegd"
                    experiment={_experiment}
                    variant={a}
                    extraClasses="full-width is-large is-even-larger"
                    href="#signup">
                    Weten waarin Strax belegt?
                  </TrackingButton>
                </InDepthItem>
              </InDepth>
              <InDepth extraClassNames="">
                <InDepthItem
                  title="Beleg mee met pensioenfondsen"
                  image={`/assets/variant-${a}/sparen-beleggen.png`}>
                  <p>
                    Strax werkt samen met grote pensioenfondsen die samen meer
                    dan 450 miljard euro beleggen. De afgelopen jaren zijn
                    rendementen behaald van gemiddeld 7% op jaarbasis. Strax
                    maakt het mogelijk mee te beleggen met pensioenfondsen en zo
                    gebruik te maken van de beste kennis, infrastructuur, en
                    mogelijkheden.
                  </p>
                </InDepthItem>
              </InDepth>
              <section name="faq-container" className="blue-gradient">
                <FaqContainer experiment={_experiment} variant={a} />
              </section>
              <Signup
                cta="Vertel mij meer over dit product!"
                explain="We willen je graag meer vertellen over de mogelijkheden van beleggen bij Strax. Het product wordt nu nog volop ontwikkeld. Meld je aan en we sturen je volledig vrijblijvend informatie toe."
                endpoint={zapierWebhook}
                tags="landing_page_prospect"
                experiment={_experiment}
                variant={a}
                successMsg="Bedankt! Wij nemen zo snel mogelijk contact op!"
              />
            </div>
          </Variant>
          <Variant name={b}>
            <div className={`variant-${b}`}>
              <StaticHeader
                logo={`/assets/variant-${b}/logo.png`}
                backgroundImage={`/assets/variant-${b}/background-image.png`}
                title="Eerder stoppen met werken? Dat kan!"
                tagline={[
                  "Investeer mee met pensioenfondsen, ",
                  <em>de weg naar al jouw dromen </em>,
                  "op een veilige manier"
                ]}
                buttonText="Ik wil meer weten"
                trackId="header-dromen"
                href="#signup"
                showRating={false}
                extraClasses="dromen"
                align="left">
                <UspBadge
                  bgImage={`/assets/variant-${b}/badge-background.png`}
                  posTop="250"
                  posRight="30">
                  <p>Een nieuwe</p>
                  <p>manier van investeren</p>
                  <a href="#signup">
                    <h1>Start nu</h1>
                  </a>
                </UspBadge>
              </StaticHeader>
              <Features>
                <Feature
                  nr="1"
                  image={`/assets/variant-${b}/rendement.png`}
                  title="Uniek hoog rendement"
                  text="Strax belegt mee met grote pensioenfondsen. Zo halen we een hoog rendement met een lager risico."
                  buttonText="Vertel me meer"
                  href="#signup"
                  extraClasses="dromen"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="2"
                  image={`/assets/variant-${b}/veilig.png`}
                  title="Extra inkomen"
                  text="Als u met pensioen gaat, keert Strax uit bovenop uw pensioen. Zo voorkomt u dat u ineens veel minder te besteden hebt, en kunt u leuke dingen blijven doen."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="3"
                  image={`/assets/variant-${b}/sleutel.png`}
                  title="Altijd direct opneembaar"
                  text="Strax werkt met een vaste inleg per maand, maar uw geld is altijd per direct opneembaar, zonder boetes of lange wachttijden."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
              </Features>
              <section name="graph-container">
                <GraphContainer experiment={_experiment} variant={b} />
              </section>
              <InDepth extraClassNames="">
                <InDepthItem
                  title="Nu investeren, voor strax"
                  image={`/assets/variant-${b}/busje.jpg`}
                  direction="rtl">
                  <p>
                    Natuurlijk is geld opzij zetten voor je pensioen altijd
                    verstandig. Maar wat als je tussentijds grote uitgaven hebt
                    en er niet aan kunt komen? Met Strax is dat geen probleem.
                    Als je tussentijds gebruik wilt maken van je vermogen dan
                    kun je er altijd bij. En laat je het staan? Dan groeit het
                    lekker door!
                  </p>
                </InDepthItem>
              </InDepth>
              <InDepth extraClassNames="">
                <InDepthItem
                  title="Een uniek nieuw product"
                  image="/assets/variant-ratio/pensioen-gat.png"
                  direction="ltr">
                  <p>
                    Strax werkt samen met grote pensioenfondsen die samen meer
                    dan 450 miljard euro beleggen. De afgelopen jaren zijn
                    rendementen behaald van gemiddeld 7% op jaarbasis. Strax
                    maakt het mogelijk mee te beleggen met pensioenfondsen en zo
                    gebruik te maken van de beste kennis, infrastructuur, en
                    mogelijkheden.
                  </p>
                </InDepthItem>
              </InDepth>
              <section name="faq-container" className="blue-gradient">
                <FaqContainer experiment={_experiment} variant={b} />
              </section>
              <Signup
                cta="Vertel mij meer over dit product!"
                explain="We willen je graag meer vertellen over de mogelijkheden van beleggen bij Strax. Het product wordt nu nog volop ontwikkeld. Meld je aan en we sturen je volledig vrijblijvend informatie toe."
                endpoint={zapierWebhook}
                tags="landing_page_prospect"
                experiment={_experiment}
                variant={b}
                successMsg="Bedankt! Wij nemen zo snel mogelijk contact op!"
              />
            </div>
          </Variant>
          <Variant name={c}>
            <div className={`variant-${c}`}>
              <StaticHeader
                logo={`/assets/variant-${c}/logo.png`}
                backgroundImage={`/assets/variant-${c}/background-image.jpg`}
                title="Nu investeren, voor strax."
                buttonText="Ik wil meer weten"
                trackId="header-traditional"
                href="#signup"
                showRating={false}
                extraClasses="traditional"
                align="right"
              />
              <Features>
                <Feature
                  nr="1"
                  image={`/assets/variant-${c}/rendement.png`}
                  title="Uniek hoog rendement"
                  text="Strax belegt mee met grote pensioenfondsen. Zo halen we een hoog rendement met een lager risico."
                  buttonText="Vertel me meer"
                  href="#signup"
                  extraClasses="dromen"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="2"
                  image={`/assets/variant-${c}/blad.png`}
                  title="Focus op duurzaam"
                  text="Investeren in de toekomst gaat om meer dan alleen geld. Daarom belegt Strax 100% in duurzame fondsen."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="3"
                  image={`/assets/variant-${c}/hand.png`}
                  title="Altijd direct opneembaar"
                  text="Strax werkt met een vaste inleg per maand, maar uw geld is altijd  direct opneembaar, zonder boetes of wachttijd."
                  buttonText="Vertel me meer"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
              </Features>
              <section name="graph-container">
                <GraphContainer experiment={_experiment} variant={c} />
              </section>
              <InDepth extraClassNames="in-depth-1">
                <InDepthItem
                  title="Een uniek nieuw product, bovenop je huidige pensioen"
                  image={`/assets/variant-${c}/grafiek.svg`}
                  direction="rtl">
                  <p>
                    Ook bij als je pensioen al goed geregeld is betekent met
                    pensioen gaan vaak een forse daling van je inkomen. Met
                    Strax vul je dat gat op. Maak van je pensioen een feestje
                    met Strax.
                  </p>
                </InDepthItem>
              </InDepth>
              <InDepth extraClassNames="">
                <InDepthItem
                  title="Je kunt altijd bij je geld"
                  image={`/assets/variant-${c}/zonsondergang.jpg`}
                  direction="ltr">
                  <p>
                    Natuurlijk is geld opzij zetten voor je pensioen altijd
                    verstandig. Maar wat als je tussentijds grote uitgaven hebt
                    en er niet aan kunt komen? Met Strax is dat geen probleem.
                    Als je tussentijds gebruik wilt maken van je vermogen dan
                    kun je er altijd bij. En laat je het staan? Dan groeit het
                    lekker door!
                  </p>
                </InDepthItem>
              </InDepth>
              <section name="faq-container" className="blue-gradient">
                <FaqContainer experiment={_experiment} variant={c} />
              </section>
              <Signup
                cta="Vertel mij meer over dit product!"
                explain="We willen je graag meer vertellen over de mogelijkheden van beleggen bij Strax. Het product wordt nu nog volop ontwikkeld. Meld je aan en we sturen je volledig vrijblijvend informatie toe."
                endpoint={zapierWebhook}
                tags="landing_page_prospect"
                experiment={_experiment}
                variant={c}
                successMsg="Bedankt! Wij nemen zo snel mogelijk contact op!"
              />
            </div>
          </Variant>
          <Variant name={d}>
            <div className={`variant-${d}`}>
              <StaticHeader
                logo={`/assets/variant-${d}/logo.svg`}
                title="Wil je wel doorwerken tot je pensioen?"
                buttonText="Ik wil meer weten"
                trackId="header-traditional"
                href="#signup"
                showRating={false}
                extraClasses="traditional"
                tagline="Wanneer je een veeleisende carrière hebt, kan inleggen in een flexibel pensioen betekenen dat je minder kunt gaan werken of eerder met pensioen kunt gaan."
              />
              <InDepth extraClassNames="in-depth-1">
                <InDepthItem
                  title="Hoe kun je eerder met pensioen?"
                  image={`/assets/variant-${d}/charts.png`}
                  direction="rtl">
                  <p>
                  Strax wordt dan uitgekeerd bovenop jouw eigen pensioen. Door nu extra geld opzij te zetten, ontstaat de mogelijkheid om minder te gaan werken of eerder met pensioen te gaan. We gebruiken top pensioenfondsen om het beste rendement op uw maandelijkse inleg te krijgen. Met hierin het grote verschil dat je jouw geld kunt opnemen wanneer het jou uitkomt en het kunt gebruiken voor wat jij wilt.
                  </p>
                  <TrackingButton
                    button_id="waarin-belegd"
                    experiment={_experiment}
                    variant={d}
                    href="#signup">
                    Ik wil eerder stoppen
                  </TrackingButton>
                </InDepthItem>
              </InDepth>
              <Features
                title="Hoe werkt het?"
              >
                <Feature
                  nr="1"
                  image={`/assets/variant-${d}/buildings.png`}
                  title="Inleg laten regelen door jouw werkgever"
                  text="Jij geeft aan welk bedrag jouw werkgever maandelijks bij Strax dient in te leggen."
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="2"
                  image={`/assets/variant-${d}/calendar.png`}
                  title="Neem jouw geld op elk gewenst moment op"
                  text="De beleggingsrekening van Strax werkt met een vaste inleg per maand, maar is altijd per direct opneembaar."
                  href="#signup"
                  extraClasses="dromen"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
                <Feature
                  nr="3"
                  image={`/assets/variant-${d}/palmtrees.png`}
                  title="Minder werken of eerder stoppen"
                  text="Jij bepaalt uiteindelijk zelf hoe je jouw kapitaal wilt gebruiken. Wie weet bedenk je nog wel een ander mooi doel?"
                  href="#signup"
                  trackId="feature-snel-resultaat"
                  experiment={_experiment}
                />
              </Features>
              <section name="graph-container">
                <GraphContainer
                  experiment={_experiment}
                  variant={d}
                  shouldDisplayCTA={false}
                />
              </section>
              <InDepth extraClassNames="in-depth-2 blue-gradient">
                <InDepthItem
                  title="Opnemen voor een ander doel kan natuurlijk ook nog"
                  image={`/assets/variant-${d}/builder.png`}
                  direction="rtl">
                  <p>
                  Met Strax kan je een flexibel pensioen opbouwen waarvan je zelf kan bepalen hoe je dat gaat gebruiken. Ook als je het niet gebruikt om minder te gaan werken en als aanvulling op je inkomen of om eerder te stoppen met werken. Maar alles wilt opnemen voor een ander doel. Dat kan natuurlijk altijd ook nog. Kortom, maximale flexibiliteit.
                  </p>
                  <TrackingButton
                    button_id="ander-doel"
                    experiment={_experiment}
                    variant={d}
                    href="#signup">
                    Ik wil meer weten
                  </TrackingButton>
                </InDepthItem>
              </InDepth>
              <section name="faq-container" className="blue-gradient">
                <FaqContainer experiment={_experiment} variant={d} />
              </section>
              <Signup
                cta="Vertel mij meer over dit product!"
                explain="We willen je graag meer vertellen over de mogelijkheden van beleggen bij Strax. Meld je aan en we sturen je volledig vrijblijvend informatie toe."
                endpoint={zapierWebhook}
                tags="landing_page_prospect"
                experiment={_experiment}
                variant={d}
                successMsg="Bedankt! Wij nemen zo snel mogelijk contact op!"
              />
            </div>
          </Variant>
        </Experiment>
        <footer>
          <div className="container">
            <div className="columns">
              <div className="column content has-text-left">
                <p>
                  Strax is een initiatief van{" "}
                  <a href="https://themainingredient.co">The Main Ingredient</a>{" "}
                  in samenwerking met Pensioenfondsen.
                </p>
              </div>
              <div className="column content has-text-right footer-links">
                <a href="https://facebook.com/voor.strax">
                  <img
                    src="/assets/f_logo_RGB-Blue_58.png"
                    alt="Facebook Voor Strax"
                  />
                </a>
                <a href="https://themainingredient.co/contact">Contact</a>{" "}
                <a href="/assets/privacy-policy-voor-strax.pdf">
                  Privacy Statement
                </a>{" "}
                <a href="/assets/disclaimer-voor-strax.pdf">Disclaimer</a>
              </div>
            </div>
            <div className="columns">
              <div className="column content has-text-left disclaimer">
                <p>
                  *) Rendementen uit het verleden bieden nooit garanties voor de
                  toekomst, <br />
                  maar langetermijn beleggingen met brede spreiding verkleinen
                  de risico's.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <CookieConsent />
      </div>
    );
  }
}

export default addUrlProps({ urlPropsQueryConfig })(App);
