export const get_object_keys_array = (object) => {
  let array = [];
  for (const [, value] of Object.entries(object)) {

    array.push(value)
  }

  return array;
}

export const get_ages_array = (yearsIntervals) => {
  let ages = [];

  for (const [key] of Object.entries(yearsIntervals)) {
    ages.push(key)
  }

  return ages;
}
