import React from "react";

export default class TrackingButton extends React.Component {
  // @TODO
  // Segment:
  // ExperimentName
  // VariationName
  //
  onClick = () => {
    window.analytics.track("Button clicked", {
      button_id: this.props.id,
      experimentName: this.props.experiment,
      variantName: this.props.variant
    });

    if (this.props.href) {
      window.location.href = this.props.href;
    }
  };

  render() {
    let classes = "button " + this.props.extraClasses;
    return (
      <button className={classes} onClick={this.onClick}>
        {this.props.children}
      </button>
    );
  }
}
