import React, { useState, useRef, useEffect } from 'react';
import Minus from '../static/minus';
import Plus from '../static/plus';

const Collapsible = ({title, content, experiment, variant, id}) => {
  const [isOpen, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [collapsibleHeight, setCollapsibleHeight] = useState(0);
  const contentEl = useRef();
  const collapsibleEl = useRef();

  useEffect(() => {
    setContentHeight(contentEl.current.clientHeight);
  }, [isOpen])

  useEffect(() => {
    setCollapsibleHeight(collapsibleEl.current.clientHeight);
  }, [])

  const trackToggle = () => {
    window.analytics.track("handle_faq_question_toggle", {
      experimentName: experiment,
      variantName: variant,
      button_id: id
    });
  }

  const handleClick = () => {
    setOpen(!isOpen);
    trackToggle();
  }

  return (
    <div ref={collapsibleEl} className={`collapsible ${isOpen ? 'is-open' : ''}`} onClick={handleClick}>
      <p className="collapsible__title">{title}</p>
      <div className={`collapsible__content-wrapper`} style={{height: isOpen ? contentHeight + 16 : 0}}>
        <p ref={contentEl} className="collapsible__content">{content}</p>
      </div>
      <div className="collapsible__icon" style={{top: collapsibleHeight / 2}}>
        {isOpen ? <Minus /> : <Plus />}
      </div>
    </div>
  )
};

Collapsible.defaultProps = {
  title: 'collapsible_title',
  content: 'collapsible_content',
}

export default Collapsible;
