import React from "react";
import classNames from "classnames";

export default class InDepth extends React.Component {
  // TODO ADD CAROUSEL

  render() {
    return (
      <section className={classNames("in-depth", this.props.extraClassNames)}>
        <a name="in-depth" />
        <div className="container">
          {this.props.title ? (
            <h2 className="section-title">{this.props.title}</h2>
          ) : (
            ""
          )}
          <div className="items">{this.props.children}</div>
        </div>
      </section>
    );
  }
}
