import React from 'react'

const SvgComponent = props => (
  <svg width={12} height={12} {...props}>
    <title>{'Plus'}</title>
    <g fill="#838099" fillRule="evenodd">
      <path d="M0 4.5h12v3H0z" />
      <path d="M7.5 0v12h-3V0z" />
    </g>
  </svg>
)

export default SvgComponent
