import React from "react";

export default class StaticHeader extends React.Component {
  render() {
    const {
      logo,
      backgroundImage,
      cssId,
      title,
      tagline,
      href,
      buttonText,
      align
    } = this.props;

    var alignItems;

    align === "right" ? (alignItems = "flex-end") : (alignItems = "flex-start");

    var inlineStyle = {};

    if (backgroundImage) {
      inlineStyle = { backgroundImage: `url(${backgroundImage})` };
    }

    return (
      <section id={cssId} className="hero is-medium header">
        <a name="header" />
        <div className="static-header hero" style={inlineStyle}>
          <div className="logo-bar">
            <img src={logo} />
          </div>
          <div
            className="header-content container columns is-desktop"
            style={{ alignItems: alignItems }}>
            <div className="header-item-box column">
              <p className="header-item-title" style={{ textAlign: align }}>
                {title}
              </p>
              <p className="header-item-tagline" style={{ textAlign: align }}>
                <span className="highlight">{tagline}</span>
              </p>
              <div
                className="header-item-button-wrapper"
                style={{ textAlign: align }}>
                <a className="header-item-button button" href={href}>
                  {buttonText}
                </a>
              </div>
            </div>
            {this.props.children}
          </div>
        </div>
      </section>
    );
  }
}
