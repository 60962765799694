import momment from 'moment';

const CURRENT_YEAR = parseInt(momment().format("YYYY"));

export const yearsIntervals = {
  5: 1,
  10: 1,
  20: 2,
  30: 5,
  40: 5,
  50: 5,
}

export const get_date_labels = (years) => {
  let divider = yearsIntervals[years];
  let initialYear = CURRENT_YEAR;

  let array = [];
  let i;

  for (i = 1; i <= years ; i += divider) {
    array.push(initialYear + i);
  }
  return array
}


