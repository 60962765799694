import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { DebounceInput } from "react-debounce-input";
import { yearly_compounds, intervaled_compounds } from './helpers/compounds_calculations';
import { get_ages_array, get_object_keys_array } from './helpers/array_transformers';
import { yearsIntervals, get_date_labels } from './helpers/years_calculations';
import { YearsDropdown } from './components/YearsDropdown';
import ToggleButton from '../../components/ToggleButton';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';


const SCENARIOS = {
  BEST: 11,
  AVERAGE: 7,
  WORST: 0.5,
  SAVINGS: 0.01,
}

const graphSettingsColors = {
  goodScenario:{
    backgroundColor: "rgba(254, 29, 0, 0.1)",
    borderColor: "transparent"
  },
  averageScenario:{
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "red"
  },
  worstScenario:{
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "transparent"
  },
  linearScenario:{
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "rgb(72, 197, 218)"

  }
}
const INITIAL_DEPOSIT = 0

export const GraphContainer = ({scenarios, experiment, variant, shouldDisplayCTA = true}) => {
  const [isAgeDropdownActive, setAgeDropDownState] = useState(false);
  const [years] = useState(get_ages_array(yearsIntervals));
  const [monthlyContribution, setMonthlyContribution] = useState(100);
  const [selectedYears, setSelectedYears] = useState(20);
  const [diffenceValue, setDifferenceValue] = useState(0);


  useEffect(() => {
    switch (variant) {
      case 'zwaar':
       graphSettingsColors.goodScenario.backgroundColor = "rgb(109, 225, 169, 0.1)"
       graphSettingsColors.averageScenario.borderColor = "rgb(109, 225, 169)"
       graphSettingsColors.linearScenario.borderColor = "rgb(72, 197, 218)"
        break;

      default:
        break;
    }


    let chart;
    const ctx = document.getElementById('pensionChart').getContext('2d');

    let bestScenarioCompounds = yearly_compounds(selectedYears, INITIAL_DEPOSIT, monthlyContribution, SCENARIOS.BEST )
    let bestScenarioArray = get_object_keys_array(bestScenarioCompounds);

    let averageScenarioCompounds = yearly_compounds(selectedYears, INITIAL_DEPOSIT, monthlyContribution, SCENARIOS.AVERAGE )
    let averageScenarioArray = get_object_keys_array(averageScenarioCompounds);

    let worstScenarioCompounds = yearly_compounds(selectedYears, INITIAL_DEPOSIT, monthlyContribution, SCENARIOS.WORST )
    let worstScenarioArray = get_object_keys_array(worstScenarioCompounds);

    let linearScenarioCompounds = yearly_compounds(selectedYears, INITIAL_DEPOSIT, monthlyContribution, SCENARIOS.SAVINGS )
    let linearScenarioArray = get_object_keys_array(linearScenarioCompounds);

    let differenceValue = calculateSavingsDifference(averageScenarioArray.slice(-1), linearScenarioArray.slice(-1))
    setDifferenceValue(differenceValue)

    chart = new Chart(ctx, {
      type: 'line',
      data: {
          labels: get_date_labels(selectedYears),
          datasets: [
            {
              label: 'Goed/Slecht scenario',
              data: intervaled_compounds(bestScenarioArray, yearsIntervals[selectedYears]),
              backgroundColor: graphSettingsColors.goodScenario.backgroundColor,
              borderColor: graphSettingsColors.goodScenario.borderColor,
              borderWidth: 0,
              pointRadius: 0,
              fill: "+2",
            },
            {
              label: 'NettoMeeBeleggen',
              data: intervaled_compounds(averageScenarioArray, yearsIntervals[selectedYears]),
              backgroundColor: graphSettingsColors.averageScenario.backgroundColor,
              borderWidth: 2,
              borderColor: graphSettingsColors.averageScenario.borderColor
            },
            {
              label: 'linear',
              data: intervaled_compounds(worstScenarioArray, yearsIntervals[selectedYears]),
              backgroundColor: graphSettingsColors.worstScenario.backgroundColor,
              borderWidth: 0,
              borderColor: graphSettingsColors.worstScenario.borderColor
            },
            {
              label: 'Spaarrekening',
              data: intervaled_compounds(linearScenarioArray, yearsIntervals[selectedYears]),
              backgroundColor: graphSettingsColors.linearScenario.backgroundColor,
              borderColor: graphSettingsColors.linearScenario.borderColor,
              borderWidth: 2,
            }
          ]
      },
      options: {
          scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: (value) => {

                    return numeral(value).format('0 a');
                  },
                  fontColor: 'rgb(155, 166, 183)',
                  fontSize: '14',
                  fontFamily: 'Patron-Bold'
                },
                gridLines: {
                  color: 'rgb(61, 64, 85, 0.1)',
                  zeroLineWidth: 3,
                  zeroLineColor: 'rgb(61, 64, 85)',
                },
              }],
              xAxes: [{
                ticks: {
                  fontColor: 'rgb(155, 166, 183)',
                  fontSize: '14',
                  fontFamily: 'Patron-Regular'
                },
                gridLines: {
                  color: 'rgb(61, 64, 85, 0.1)',
                },
              }]
          },
          legend: {
            position: 'bottom',
            labels: {
              filter: function(item, chart) {
                  // Logic to remove a particular legend item goes here
                  return !item.text.includes('linear');
              }
          }
          },
      }
    });

    return () => {
      chart.destroy()
    };
  }, [years, monthlyContribution, selectedYears, variant]);

  const handleAgeDropDownClick = () => {
    window.analytics.track("handle_age_drop_down_click", {
      experimentName: experiment,
      variantName: variant
    });
    setAgeDropDownState(!isAgeDropdownActive)
  }

  const handleAgeClick = (age) => {
    window.analytics.track("handle_age_click", {
      experimentName: experiment,
      variantName: variant
    });
    setSelectedYears(age)
  }

  const handleMonthlyContributionsChange = (value) => {
    window.analytics.track("handle_monthly_contributions_change", {
      experimentName: experiment,
      variantName: variant
    });
    setMonthlyContribution(value)
  }

  const calculateSavingsDifference = (averageValue, savingsValue) => {
    const difference = averageValue - savingsValue;

    return numeral(Math.round(difference)).format('0,0.')
  }

  return (
    <div className="container graph-container">
      <h2>Wat levert mijn inleg op? <br/>Verken de scenario's hier.</h2>
      <div className="scenarios">
        <p className="scenarios__title">Mijn spaardoel(en):</p>
        <div className="scenarios__toggle-wrapper">
          {scenarios.map(scenario => <ToggleButton bgcolor="#6a08fe" title={scenario} experiment={experiment} variant={variant} />)}
        </div>
      </div>
      <div>
        <div className="graph-controls-wrapper">
          <label className="control-label">Maandelijkse inleg:</label>
          <span className="control-span">€</span>
          <DebounceInput
            debounceTimeout={300}
            type="number"
            onChange={event => handleMonthlyContributionsChange(event.target.value)}
            value={monthlyContribution}
          />
          <label className="control-label">Tijdsbestek:</label>
          <YearsDropdown
            handleAgeDropDownClick={handleAgeDropDownClick}
            handleAgeClick={handleAgeClick}
            selectedYears={selectedYears}
            years={years}
            isAgeDropdownActive={isAgeDropdownActive}
            />
        </div>
      </div>
      <div className="graph-wrapper">
        <canvas id="pensionChart" width="400" height="250"></canvas>
      </div>
      <div className="summary-container">
        <h2>Je doel kan behaald worden!</h2>
        <p className="summary-container-introduction">
          In <span className="text__bold">{selectedYears} jaar</span> tijd kan je een extra <span className="summary-difference text__bold">€ {diffenceValue}</span> opbouwen vergeleken met je spaarrekening.
        </p>
        {shouldDisplayCTA && <a className="graph-button header-item-button button" href="#signup">
          <span>Begin met sparen</span>
          <FontAwesomeIcon icon={faCaretRight} size="lg" color="white"/>
        </a>}
      </div>
    </div>
  )
}

GraphContainer.defaultProps = {
  scenarios: [
    "Studie van kinderen",
    "Eerder stoppen met werken",
    "Een wereldreis maken",
    "Een camper kopen",
    "Een relaxed pensioen",
    "Minder werken"
  ]
};
