import React from "react";
import classNames from "classnames";

export default class InDepthItem extends React.Component {
  render() {
    // let styles = { backgroundImage: `url(${this.props.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }

    return (
      <div className="in-depth-item">
        <div className={classNames("columns", this.props.direction)}>
          <div className="column in-depth-image-column">
            <div className="in-depth-image-wrapper">
              <img className="in-depth-image" src={this.props.image} />
            </div>
          </div>
          <div className="in-depth-text-wrapper column">
            <div className="in-depth-text">
              <h2>{this.props.title}</h2>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
