import React, { useState } from 'react';

const ToggleButton = ({isActive, title, experiment, variant, bgcolor}) => {
  const [isActiveState, setActive] = useState(isActive);

  const handleClick = () => {
    window.analytics.track("handle_monthly_contributions_change", {
      experimentName: experiment,
      variantName: variant
    });
    setActive(!isActiveState);
  }

  return (
    <div className={`toggle-button ${isActiveState ? 'is-active' : ''}`} style={{backgroundColor: isActiveState ? bgcolor : null}} onClick={handleClick}>
      <p className={`toggle-button__title ${isActiveState ? 'is-active' : ''}`}>{title}</p>
    </div>
  );
};

ToggleButton.defaultProps = {
  isActive: false,
  title: 'Studie van kinderen',
}

export default ToggleButton;
